import React, { useState, useEffect, useContext } from "react"
import styled from "styled-components"
import tw from "twin.macro"
import { getAllTimezones, getTimezone, Timezone } from "countries-and-timezones"
import orderBy from "lodash-es/orderBy"
import {
  AlarmHintEnum,
  AlarmSchedule,
  DateIntervalSchedule,
  WeekdaySchedule,
} from "routes/Alarms/alarm.types"
import Accordion from "lib/Accordion"
import Icon, { IconSizeEnum } from "lib/Icon"
import { EditorPanelHeader } from "../editor/Header"
import { WeekdayEnum } from "lib/global.types"
import Checkbox from "lib/Checkbox"
import { Select } from "lib/Select"
import { v4 } from "uuid"
import DateFilter from "app/History/DateFilter"
import { Input, FormField, Responsive } from "@clevertrack/shared"
import { EditorButtonGroup } from "../editor/EditorButtonGroup"
import addSeconds from "date-fns/addSeconds"
import { updateCollectionItemByID } from "utils/array"
import { AlarmsContext } from "routes/Alarms/context"
import { Hint, HintIcon } from "app/Hint"
import { useUser } from "app/User/hooks"

type AlertScheduleProps = {
  onSave?: (input: AlarmSchedule) => void
  onCancel?: () => void
}

const StyledSchedule = styled.div`
  ${tw`pb-32 bg-white min-h-full`}
`

const StyledWeekday = styled.li`
  ${tw`py-4 md:(flex items-center p-4) hover:(bg-brand-gray-light)`}
`

const StyledTimeInput = styled(Input)`
  ${tw`m-0 p-2 border-0`}
`

const defaultTimespan: [string, string, boolean] = ["08:00", "16:00", false]

const defaultWeekdaySchedule: WeekdaySchedule = {
  [WeekdayEnum.Monday]: defaultTimespan,
  [WeekdayEnum.Tuesday]: defaultTimespan,
  [WeekdayEnum.Wednesday]: defaultTimespan,
  [WeekdayEnum.Thursday]: defaultTimespan,
  [WeekdayEnum.Friday]: defaultTimespan,
}

const weekdays = [
  { key: WeekdayEnum.Monday, name: "Mandag" },
  { key: WeekdayEnum.Tuesday, name: "Tirsdag" },
  { key: WeekdayEnum.Wednesday, name: "Onsdag" },
  { key: WeekdayEnum.Thursday, name: "Torsdag" },
  { key: WeekdayEnum.Friday, name: "Fredag" },
  { key: WeekdayEnum.Saturday, name: "Lørdag" },
  { key: WeekdayEnum.Sunday, name: "Søndag" },
]

const allTimezones = getAllTimezones()

const defaultTimezone = getTimezone("Europe/Copenhagen")

const defaultOption = {
  label: `GMT${defaultTimezone.utcOffsetStr} (${defaultTimezone.name})`,
  value: defaultTimezone,
}

export const AlertSchedule: React.FC<AlertScheduleProps> = ({
  onSave,
  onCancel,
  ...props
}) => {
  const {
    state: { currentSchedule },
  } = useContext(AlarmsContext)
  const [weekdayScheduleToggled, setWeekdayScheduleToggled] = useState(false)
  const [intervalScheduleToggled, setIntervalScheduleToggled] = useState(false)
  const [weekdaySchedule, setWeekdaySchedule] = useState<WeekdaySchedule>({})
  const [intervals, setIntervals] = useState<DateIntervalSchedule[]>([])
  const [animationEnded, setAnimationEnded] = useState<boolean>(false)
  const [animationEndedTimezone, setAnimationEndedTimezone] = useState<boolean>(
    false
  )
  const [selectedTimezone, setSelectedTimezone] = useState<Timezone>(
    defaultTimezone
  )
  const [triggerInsideSchedule, setTriggerInsideSchedule] = useState<boolean>(
    currentSchedule?.triggerInsideSchedule ?? false
  )
  const { getUserVisibilitySetting } = useUser()

  const timezoneMap = orderBy(
    Object.entries(allTimezones).map(([key, value]) => {
      return {
        label: `GMT${value.utcOffsetStr} (${key})`,
        value,
        isSelected: key === defaultTimezone.name,
      }
    }),
    "value.utcOffset",
    "desc"
  )

  const onAddWeekdayScheduleHandler = () => {
    setWeekdaySchedule(defaultWeekdaySchedule)
    setWeekdayScheduleToggled((prev) => !prev)
  }

  const onAddIntervalScheduleHandler = () => {
    setIntervals((prev) => [
      ...prev,
      {
        id: v4(),
        interval: [+new Date(), +addSeconds(new Date(), 3600 * 24 * 7)],
      },
    ])
    if (!intervalScheduleToggled) {
      setIntervalScheduleToggled((prev) => !prev)
    }
  }

  const onRemoveIntervalHandler = (id) => {
    setIntervals((prev) => prev.filter((x) => x.id !== id))
  }

  const onDeleteWeekSchedule = () => {
    setWeekdaySchedule({})
    setWeekdayScheduleToggled((prev) => !prev)
  }

  const onToggleWeekday = (toggled, key) => {
    const newWeekdaySchedule = {
      ...weekdaySchedule,
    }
    if (toggled) {
      newWeekdaySchedule[key] = defaultTimespan
    } else {
      delete newWeekdaySchedule[key]
    }
    setWeekdaySchedule(newWeekdaySchedule)
  }

  const onChangeWeekdayTo = (key, value) => {
    const newWeekdaySchedule = {
      ...weekdaySchedule,
      [key]: [value, weekdaySchedule[key][1], false],
    }
    setWeekdaySchedule(newWeekdaySchedule)
  }

  const onChangeWeekdayFrom = (key, value) => {
    const newWeekdaySchedule = {
      ...weekdaySchedule,
      [key]: [weekdaySchedule[key][0], value, false],
    }
    setWeekdaySchedule(newWeekdaySchedule)
  }

  const onSetWeekdayEntireDay = (checked, key) => {
    const newWeekdaySchedule = {
      ...weekdaySchedule,
      [key]: [weekdaySchedule[key][0], weekdaySchedule[key][1], checked],
    }
    setWeekdaySchedule(newWeekdaySchedule)
  }

  const onChangeInterval = (intervalID, date, index) => {
    const pendingIntervalChange = intervals.find(
      (interval) => interval.id === intervalID
    )
    if (pendingIntervalChange && pendingIntervalChange.interval) {
      pendingIntervalChange.interval[index] = +new Date(date)
      const newIntervals = updateCollectionItemByID<DateIntervalSchedule>(
        intervals,
        intervalID,
        pendingIntervalChange
      )
      setIntervals(newIntervals)
    }
  }

  const onSaveHandler = () => {
    if (onSave) {
      let payload
      if (currentSchedule && currentSchedule.id) {
        payload = {
          ...currentSchedule,
          timezone: selectedTimezone,
          triggerInsideSchedule,
        }
        if (Object.keys(weekdaySchedule).length > 0) {
          payload.weekdays = weekdaySchedule
        } else {
          delete payload.weekdays
        }
        if (intervals.length > 0) {
          payload.intervals = intervals
        } else {
          delete payload.intervals
        }
        onSave(payload)
      } else {
        payload = {
          id: v4(),
          timezone: selectedTimezone,
          triggerInsideSchedule,
        }
        if (Object.keys(weekdaySchedule).length > 0) {
          payload.weekdays = weekdaySchedule
        }
        if (intervals.length > 0) {
          payload.intervals = intervals
        }
        onSave(payload)
      }
    }
  }

  const onCancelHandler = () => {
    if (onCancel) onCancel()
  }

  const onResetSchedule = () => {
    setWeekdayScheduleToggled(false)
    setIntervalScheduleToggled(false)
    setWeekdaySchedule({})
    setIntervals([])
    setAnimationEnded(false)
    setAnimationEndedTimezone(false)
    setSelectedTimezone(defaultTimezone)
  }

  useEffect(() => {
    if (intervals.length === 0) setIntervalScheduleToggled(false)
  }, [intervals])

  useEffect(() => {
    return () => {
      onResetSchedule()
    }
  }, [])

  useEffect(() => {
    if (currentSchedule) {
      if (currentSchedule.weekdays) {
        setWeekdayScheduleToggled(true)
        setWeekdaySchedule(currentSchedule.weekdays)
      }
      if (currentSchedule.intervals) {
        setIntervalScheduleToggled(true)
        setIntervals(currentSchedule.intervals)
      }
      setSelectedTimezone(currentSchedule.timezone)
    } else onResetSchedule()
  }, [currentSchedule])

  return (
    <>
      <EditorPanelHeader onBack={onCancelHandler}>
        Tidsplan for alarmering
      </EditorPanelHeader>
      <StyledSchedule>
        <Accordion toggled={weekdayScheduleToggled}>
          <header tw="my-0 pt-0">
            <h4 tw="my-0 block">
              Ugedage
              <small tw="font-normal block">
                Angiv om alarmen skal gælde alle dage eller for valgte tidsrum,
                f.eks. udenfor arbejdstid.
              </small>
            </h4>
          </header>
          <div tw="flex items-center text-xl mt-6 mb-4">
            <Checkbox
              inline
              name="triggerInsideSchedule"
              checkboxAppearance="radio"
              checked={triggerInsideSchedule === false}
              onChange={(e) => {
                setTriggerInsideSchedule(false)
              }}
            >
              <span tw="text-xl">
                Aktiver alarm udenfor perioden{" "}
                <HintIcon type={AlarmHintEnum.ScheduleWeek} />
              </span>
            </Checkbox>
            <Checkbox
              inline
              name="triggerInsideSchedule"
              checkboxAppearance="radio"
              checked={triggerInsideSchedule === true}
              onChange={(e) => {
                setTriggerInsideSchedule(true)
              }}
            >
              <span tw="text-xl">Aktiver alarm indenfor perioden</span>
            </Checkbox>
          </div>
          <Hint
            key={AlarmHintEnum.ScheduleWeek}
            type={AlarmHintEnum.ScheduleWeek}
            tw="text-xl mb-8"
          />
          <ul tw="p-0 m-0 mb-8 list-none space-y-4">
            {weekdaySchedule &&
              weekdays.map((day) => {
                const [from, to, entireDay] = weekdaySchedule[day.key] ?? [
                  "00:00",
                  "23:59",
                  false,
                ]
                let fromFormatted
                let toFormatted
                if (from && to) {
                  fromFormatted = from
                  toFormatted = to
                }
                return (
                  <StyledWeekday key={day.key}>
                    <div tw="w-48">
                      <Checkbox
                        onChange={(checked) =>
                          onToggleWeekday(checked, day.key)
                        }
                        checked={weekdaySchedule.hasOwnProperty(day.key)}
                      >
                        {day.name}
                      </Checkbox>
                    </div>
                    {day && (
                      <div
                        tw="flex items-center flex-wrap md:(flex items-center)"
                        css={
                          !weekdaySchedule.hasOwnProperty(day.key)
                            ? [tw`opacity-60 pointer-events-none`]
                            : ``
                        }
                      >
                        <div tw="flex items-center mt-4 md:(mt-0)">
                          <span tw="block mr-4 text-xl">Fra: </span>
                          <StyledTimeInput
                            size="sm"
                            type="time"
                            tw="text-xl mt-1 cursor-pointer"
                            value={fromFormatted}
                            disabled={entireDay}
                            onChange={(e) =>
                              onChangeWeekdayTo(day.key, e.target.value)
                            }
                          />
                        </div>
                        <div tw="ml-8 flex items-center mt-4 md:(mt-0)">
                          <span tw="block mr-4 text-xl">Til: </span>
                          <StyledTimeInput
                            size="sm"
                            type="time"
                            tw="text-xl mt-1 cursor-pointer"
                            value={toFormatted}
                            disabled={entireDay}
                            onChange={(e) =>
                              onChangeWeekdayFrom(day.key, e.target.value)
                            }
                          />
                        </div>
                        <div tw="ml-2 flex-grow mt-4 text-lg md:(ml-4 mt-0 flex-auto)">
                          <Checkbox
                            tw="flex items-center"
                            onChange={(checked) =>
                              onSetWeekdayEntireDay(checked, day.key)
                            }
                            appearance="toggle"
                            checked={
                              weekdaySchedule.hasOwnProperty(day.key) &&
                              weekdaySchedule[day.key][2]
                            }
                          >
                            Hele dagen
                          </Checkbox>
                        </div>
                      </div>
                    )}
                  </StyledWeekday>
                )
              })}
          </ul>
          <span
            tw="flex items-center text-brand-red-500 cursor-pointer cursor-pointer hover:opacity-60 transition mb-12"
            onClick={onDeleteWeekSchedule}
          >
            <span tw="mr-2 text-xl border-dotted border-0 border-b ">
              Slet ugeskema
            </span>
            <Icon size={IconSizeEnum.SM} icon="close" />
          </span>
        </Accordion>
        {!weekdayScheduleToggled ? (
          <span
            tw="flex items-center text-brand-500 cursor-pointer cursor-pointer hover:opacity-60 transition"
            onClick={onAddWeekdayScheduleHandler}
          >
            <span tw="mr-2 text-xl border-dotted border-0 border-b ">
              Tilføj ugedage
            </span>
            <Icon size={IconSizeEnum.SM} icon="plus" />
          </span>
        ) : null}

        <Accordion
          toggled={intervalScheduleToggled}
          onStart={() => setAnimationEnded(false)}
          onRest={() => setAnimationEnded(intervalScheduleToggled)}
          css={[animationEnded ? tw`overflow-visible` : ``]}
        >
          <header tw="my-0 pt-8 mb-4 md:(mb-0 pt-0)">
            <h4 tw="my-0 block">
              Perioder <HintIcon type={AlarmHintEnum.SchedulePeriod} />
              <small tw="font-normal block">
                Angiv i hvilke perioder alarmen skal være aktiveret døgnet
                rundt, f.eks i ferier.
              </small>
            </h4>
          </header>
          <Hint
            key={AlarmHintEnum.SchedulePeriod}
            type={AlarmHintEnum.SchedulePeriod}
            tw="text-xl mb-8"
            toggled={weekdayScheduleToggled}
          />
          <ul tw="p-0 m-0 mb-8 list-none space-y-4">
            {intervals.map((interval) => {
              return (
                <div key={interval.id} tw="flex">
                  <div tw="flex space-y-0">
                    <DateFilter
                      key="from"
                      date={new Date(interval.interval[0])}
                      dateUpper={new Date(interval.interval[1])}
                      label="Fra dato"
                      onSelectDate={(d) => onChangeInterval(interval.id, d, 0)}
                    />
                    <DateFilter
                      key="to"
                      date={new Date(interval.interval[1])}
                      dateLower={new Date(interval.interval[0])}
                      label="Til dato"
                      onSelectDate={(d) => onChangeInterval(interval.id, d, 1)}
                      dropdownAttachment="right"
                    />
                  </div>
                  <span
                    tw="flex items-center text-brand-red-500 ml-4 cursor-pointer hover:opacity-60 transition"
                    onClick={() => onRemoveIntervalHandler(interval.id)}
                  >
                    <span tw="mr-2 text-xl">Slet</span>
                    <Icon size={IconSizeEnum.SM} icon="close" />
                  </span>
                </div>
              )
            })}
          </ul>
        </Accordion>
        <span
          tw="flex items-center text-brand-500 mt-4 cursor-pointer cursor-pointer hover:opacity-60 transition mb-12"
          onClick={onAddIntervalScheduleHandler}
        >
          <span tw="mr-2 text-xl border-dotted border-0 border-b ">
            Tilføj periode
          </span>
          <Icon size={IconSizeEnum.SM} icon="plus" />
        </span>
        <Accordion
          toggled={intervalScheduleToggled || weekdayScheduleToggled}
          onStart={() => setAnimationEndedTimezone(false)}
          onRest={() =>
            setAnimationEndedTimezone(
              intervalScheduleToggled || weekdayScheduleToggled
            )
          }
          css={[animationEndedTimezone ? tw`overflow-visible` : ``]}
        >
          <header tw="my-0 pt-8 mb-4 md:(mb-0 pt-0)">
            <h4 tw="my-0 block">
              Tidszone
              <small tw="font-normal block">
                Angiv hvilken tidszone tidsplanen gælder for
              </small>
            </h4>
          </header>
          <FormField label="Vælg tidszone">
            <Select
              options={timezoneMap}
              defaultValue={defaultOption}
              onChange={(opt) => setSelectedTimezone(opt.value)}
            />
          </FormField>
        </Accordion>
        {onSave && onCancel && (
          <Responsive
            phone={
              <EditorButtonGroup
                sticky
                onSave={onSaveHandler}
                onCancel={onCancelHandler}
              />
            }
            tabletLandscape={
              <EditorButtonGroup
                sticky={false}
                onSave={onSaveHandler}
                onCancel={onCancelHandler}
              />
            }
          />
        )}
      </StyledSchedule>
    </>
  )
}
