import React, { useContext, useRef, useState, useEffect } from "react"
import styled from "styled-components"
import { FormField, Textarea, Responsive } from "@clevertrack/shared"
import tw from "twin.macro"
import {
  AlertNotification,
  IAlarmNotificationRecipient,
} from "routes/Alarms/alarm.types"
import { EditorPanelHeader } from "../editor/Header"
import Accordion from "lib/Accordion"
import { AlarmsContext } from "routes/Alarms/context"
import Input from "lib/Input"
import { Select } from "lib/Select"
import at from "lodash-es/at"
import { Recipients } from "./Recipients"
import { EditorButtonGroup } from "../editor/EditorButtonGroup"
import { AlarmActions, AlarmTypes } from "routes/Alarms/actions"
import { IconSizeEnum } from "lib/Icon"
import Icon from "app/Icon"
import { IconFlip } from "app/IconFlip"
import Checkbox from "lib/Checkbox"
import useDevicesStore from "app/Device/store"
import { getAllTimezones, getTimezone, Timezone } from "countries-and-timezones"
import orderBy from "lodash-es/orderBy"

type NotificationsProps = {
  onSave?: (notification: AlertNotification) => void
  onCancel?: () => void
}

const StyledNotifications = styled.div`
  ${tw`relative pb-32 bg-white min-h-full`}
`

enum MergeFieldEnum {
  FNAME = "FNAME",
  TITLE = "TITLE",
  DESCRIPTION = "DESCRIPTION",
  DEVICE = "DEVICE",
  LINK = "LINK",
}

type MergeFieldMap = {
  key: MergeFieldEnum
  description: string
  interpolate: <T>(input: T) => any[]
}

const mergeFields: MergeFieldMap[] = [
  {
    key: MergeFieldEnum.FNAME,
    description: "Modtagerens fornavn",
    interpolate: (input) => at(input, "first_name"),
  },
  {
    key: MergeFieldEnum.TITLE,
    description: "Alarmens titel",
    interpolate: (input) => at(input, "title"),
  },
  {
    key: MergeFieldEnum.DESCRIPTION,
    description: "Alarmens beskrivelse",
    interpolate: (input) => at(input, "description"),
  },
  {
    key: MergeFieldEnum.DEVICE,
    description: "Enheden der udløste alarmen",
    interpolate: (input) => at(input, "name"),
  },
  {
    key: MergeFieldEnum.LINK,
    description: "Link til visning af enhedens alarm",
    interpolate: (input) => at(input, "id"),
  },
]

const defaultSuggestedSubject = `{TITLE} på {DEVICE} blev udløst`

const defaultSuggestedMessage = `Hej {FNAME}

'{TITLE}' blev udløst på enheden '{DEVICE}'.

Klik på linket herunder for at se enheden:

{LINK}
`

const frequencyOptions = [
  {
    label: "Hvert kvarter",
    value: 900,
  },
  {
    label: "Hver halve time",
    value: 1800,
  },
  {
    label: "Hver time",
    value: 3600,
  },
  {
    label: "Hver 2. time",
    value: 3600 * 2,
  },
  {
    label: "Hver 3. time",
    value: 3600 * 3,
  },
  {
    label: "Hver 6. time",
    value: 3600 * 6,
  },
  {
    label: "Én gang i døgnet",
    value: 3600 * 24,
  },
  {
    label: "Hver 2. dag",
    value: 3600 * 48,
  },
  {
    label: "Ugentligt",
    value: 3600 * 24 * 7,
  },
]

const interpolate = (input: string, params: { [key: string]: any }) => {
  let output = input
  Object.entries(params).forEach(([key, value]) => {
    output = output.replace(new RegExp(`\{${key}\}`, "g"), value)
  })
  return output
}

const allTimezones = getAllTimezones()

const defaultTimezone = getTimezone("Europe/Copenhagen")

const defaultOption = {
  label: `GMT${defaultTimezone.utcOffsetStr} (${defaultTimezone.name})`,
  value: defaultTimezone,
}

export const AlertNotifications: React.FC<NotificationsProps> = ({
  onSave,
  onCancel,
  ...props
}) => {
  const {
    state: { editAlarm, currentNotification },
    dispatch,
  } = useContext(AlarmsContext)
  const { devices } = useDevicesStore((state) => ({
    devices: state.devices,
  }))

  const [subject, setSubject] = useState<string>(defaultSuggestedSubject)
  const [message, setMessage] = useState<string>(defaultSuggestedMessage)
  const [showMessageUI, setShowMessageUI] = useState<boolean>(false)
  const [showMergeFields, setShowMergeFields] = useState<boolean>(false)
  const [showFrequency, setShowFrequency] = useState<boolean>(false)
  const [showExample, setShowExample] = useState<boolean>(false)
  const [notificationFrequency, setNotificationFrequency] = useState(
    currentNotification?.notificationTriggerFrequency || 0
  )
  const [
    notificationDisableTriggerOnInitialCriteriaFullfilment,
    setNotificationDisableTriggerOnInitialCriteriaFullfilment,
  ] = useState(
    currentNotification?.notificationDisableTriggerOnInitialCriteriaFullfilment ||
      false
  )
  const [selectedTimezone, setSelectedTimezone] = useState<Timezone>(
    defaultTimezone
  )

  const timezoneMap = orderBy(
    Object.entries(allTimezones).map(([key, value]) => {
      return {
        label: `GMT${value.utcOffsetStr} (${key})`,
        value,
        isSelected: key === defaultTimezone.name,
      }
    }),
    "value.utcOffset",
    "desc"
  )

  const textareaRef = useRef<HTMLTextAreaElement>()
  const textareaMemoizedCursorPosition = useRef(0)

  const [selectedRecipients, setSelectedRecipients] = useState<
    IAlarmNotificationRecipient[]
  >([])

  const onSelectRecipientHandler = (recipient) => {
    setSelectedRecipients((prev) => [...prev, recipient])
  }

  const onRemoveRecipientHandler = (recipientID) => {
    setSelectedRecipients((prev) => prev.filter((x) => x.id !== recipientID))
  }

  const onSuggestMessage = (e) => {
    e.preventDefault()
    setSubject(defaultSuggestedSubject)
    setMessage(defaultSuggestedMessage)
  }

  const renderMergeField = ({ key, description }) => {
    return (
      <li key={key}>
        <span tw="font-bold w-48 inline-block">
          <span
            tw="cursor-pointer border-0 border-b border-dotted"
            title="Tryk for at indsætte"
            onClick={(e) => insertMergeFieldAtCursor(e, key)}
          >{`{${key}}`}</span>
        </span>
        <span tw="ml-4 inline-block">{description}</span>
      </li>
    )
  }

  const insertMergeFieldAtCursor = (e: React.MouseEvent, key) => {
    e.preventDefault()
    if (textareaRef.current) {
      const newMessage = [
        message.substring(0, textareaMemoizedCursorPosition.current),
        `{${key}}`,
        message.substring(textareaMemoizedCursorPosition.current),
      ].join("")
      setMessage(newMessage)
      textareaRef.current.focus()
      textareaRef.current.selectionEnd = textareaMemoizedCursorPosition.current
    }
  }

  const interpolateAlertNotification = () => {
    const values = {}
    if (editAlarm) {
      for (const field of mergeFields) {
        switch (field.key) {
          case MergeFieldEnum.FNAME:
            values[field.key] =
              selectedRecipients.length > 0
                ? field.interpolate(selectedRecipients[0])[0]
                : field.key
            break
          case MergeFieldEnum.DESCRIPTION:
            values[field.key] = field.interpolate(editAlarm)[0]
            break
          case MergeFieldEnum.DEVICE:
            values[field.key] =
              editAlarm.deviceIDs && editAlarm.deviceIDs.length > 0
                ? field.interpolate(
                    devices.find((d) => d.id === +editAlarm.deviceIDs[0])
                  )[0]
                : field.key
            break
          case MergeFieldEnum.TITLE:
            values[field.key] = field.interpolate(editAlarm)[0]
            break
          case MergeFieldEnum.LINK:
            const linkHost =
              process.env.NODE_ENV === "dev"
                ? "https://localhost:8000/app/"
                : "https://app.clevertrack.dk/"
            const device =
              editAlarm.deviceIDs && editAlarm.deviceIDs.length > 0
                ? field.interpolate(
                    devices.find((d) => d.id === +editAlarm.deviceIDs[0])
                  )[0]
                : field.key
            if (device) {
              values[field.key] = [
                linkHost,
                `?deviceID=${device}`,
                editAlarm.id ? `&alarmID=${editAlarm.id}` : null,
              ]
                .filter(Boolean)
                .join("")
            }
            break
        }
      }
    }

    const interpolatedSubject = interpolate(subject, values)
    const interpolatedMessage = interpolate(message, values)

    return [interpolatedSubject, interpolatedMessage]
  }

  const renderExample = () => {
    const [sub, body] = interpolateAlertNotification()
    return (
      <div tw="border border-solid border-brand-gray-light p-4">
        <span tw="block font-bold text-xl mb-8">Emne: {sub}</span>
        <span tw="block whitespace-pre text-xl">{body}</span>
      </div>
    )
  }

  const onSaveHandler = () => {
    if (onSave) {
      if (currentNotification) {
        onSave({
          ...currentNotification,
          message,
          subject,
          recipients: selectedRecipients,
          notificationTriggerFrequency: notificationFrequency,
          notificationDisableTriggerOnInitialCriteriaFullfilment,
          timezone: selectedTimezone,
        })
      } else {
        onSave({
          alarmLastTriggeredAtDeviceMap: {},
          message,
          subject,
          recipients: selectedRecipients,
          notificationTriggerFrequency: notificationFrequency,
          notificationLastTriggeredAt: 0,
          notificationLastTriggeredAtDeviceMap: {},
          notificationReadByContactIDs: [],
          notificationDisableTriggerOnInitialCriteriaFullfilment,
          timezone: selectedTimezone,
        })
      }
    }
  }

  const onCancelHandler = () => {
    if (onCancel) onCancel()
  }

  const onShowFrequencyHandler = () => {
    setShowFrequency(true)
    setNotificationFrequency(frequencyOptions[0].value)
  }

  const onHideFrequencyHandler = () => {
    setShowFrequency(false)
    setNotificationFrequency(0)
  }

  useEffect(() => {
    if (currentNotification) {
      setSubject(currentNotification.subject)
      setMessage(currentNotification.message)
      setSelectedRecipients(currentNotification.recipients)
      setNotificationFrequency(currentNotification.notificationTriggerFrequency)
      if (currentNotification.notificationTriggerFrequency > 0) {
        setShowFrequency(true)
      }
    }
  }, [currentNotification])

  useEffect(() => {
    return () => {
      // Unmount
      dispatch(
        AlarmActions(AlarmTypes.SetCurrentAlertNotification, {
          alertNotification: null,
        })
      )
      setSubject("")
      setMessage("")
      setSelectedRecipients([])
      setNotificationFrequency(frequencyOptions[0].value)
    }
  }, [])

  return (
    <StyledNotifications id="notifications">
      <EditorPanelHeader>Underretning ved alarm</EditorPanelHeader>
      <Recipients
        onSelectRecipient={onSelectRecipientHandler}
        onRemoveRecipient={onRemoveRecipientHandler}
        selectedRecipients={selectedRecipients}
      />

      <div>
        <header tw="my-0 pt-0">
          <h4 tw="my-0 block">
            Frekvens
            <small tw="font-normal block">
              Hvor ofte skal modtagerne underrettes, når alarmen er udløst?
              <br />
              Hvis ingen frekvens er angivet, udsendes underretningen kun én
              gang.
            </small>
          </h4>
        </header>
        {!showFrequency ? (
          <span
            tw="flex items-center text-brand-500 cursor-pointer cursor-pointer hover:opacity-60 transition"
            onClick={onShowFrequencyHandler}
          >
            <span tw="mr-2 text-xl border-dotted border-0 border-b ">
              Tilføj frekvens for underretning
            </span>
            <Icon size={IconSizeEnum.SM} icon="plus" />
          </span>
        ) : (
          <>
            <FormField label="Frekvens">
              <Select
                value={frequencyOptions.find(
                  (x) => x.value === notificationFrequency
                )}
                options={frequencyOptions}
                placeholder="Vælg frekvens"
                onChange={(opt) => setNotificationFrequency(opt.value)}
                style={{ zIndex: 600 }}
              />
            </FormField>
            <Checkbox
              tw="mt-4"
              name="notificationDisableTriggerOnInitialCriteriaFullfilment"
              checked={!notificationDisableTriggerOnInitialCriteriaFullfilment}
              onChange={(e) => {
                setNotificationDisableTriggerOnInitialCriteriaFullfilment(
                  !notificationDisableTriggerOnInitialCriteriaFullfilment
                )
              }}
            >
              <span tw="text-xl">
                Udsend også underretning første gang alarmen udløses
              </span>
            </Checkbox>
            <span
              tw="flex items-center text-brand-red-500 mt-4 cursor-pointer cursor-pointer hover:opacity-60 transition"
              onClick={onHideFrequencyHandler}
            >
              <span tw="mr-2 text-xl border-dotted border-0 border-b">
                Fjern frekvens
              </span>
              <Icon size={IconSizeEnum.SM} icon="close" />
            </span>
            <header tw="my-0 pt-8 mb-4 md:(mb-0 pt-0)">
              <h4 tw="my-0 block">
                Tidszone
                <small tw="font-normal block">
                  Når en alarm bliver udløst viser vi hvornår det er sket, med
                  udgangspunkt i den valgte tidszone.
                </small>
              </h4>
            </header>
            <FormField label="Vælg tidszone">
              <Select
                options={timezoneMap}
                defaultValue={defaultOption}
                onChange={(opt) => setSelectedTimezone(opt.value)}
              />
            </FormField>
          </>
        )}
      </div>
      <div tw="mt-4">
        <header tw="my-0 mb-4 pt-0 flex justify-start items-baseline">
          <h4 tw="my-0 inline-block">Besked ved underretning</h4>
          <span
            tw="flex items-center text-brand-500 cursor-pointer cursor-pointer hover:opacity-60 transition mr-auto ml-4"
            onClick={() => setShowMessageUI((prev) => !prev)}
          >
            <span tw="mr-2 text-xl border-dotted border-0 border-b">
              {showMessageUI ? `Skjul` : `Se og redigér`}
            </span>
            <IconFlip
              iconOn="chevron-up"
              iconOff="chevron-right"
              toggled={showMessageUI}
              size={IconSizeEnum.SM}
            />
          </span>
        </header>
        <Accordion toggled={showMessageUI}>
          {!showExample ? (
            <>
              <FormField label="Emnefelt" validationKey="description">
                <Input
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                  placeholder="Indtast emnefelt (ved advisering pr. email)"
                />
              </FormField>
              <FormField label="Besked" validationKey="description">
                <Textarea
                  rows="6"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  placeholder="Indtast beskeden der udsendes når alarmen udløses"
                  forwardRef={textareaRef}
                  onBlur={(e) =>
                    (textareaMemoizedCursorPosition.current =
                      e.target.selectionEnd)
                  }
                />
              </FormField>
            </>
          ) : (
            renderExample()
          )}
          <div tw="flex space-x-8 text-brand-500 text-xl mt-4 mb-8">
            <a
              tw="cursor-pointer border-dotted border-0 border-b"
              onClick={(e) => onSuggestMessage(e)}
            >
              Foreslå besked
            </a>
            <a
              tw="cursor-pointer border-dotted border-0 border-b"
              onClick={() => setShowMergeFields((prev) => !prev)}
            >
              {showMergeFields ? `Skjul flettefelter` : `Vis flettefelter`}
            </a>
            <a
              tw="cursor-pointer border-dotted border-0 border-b"
              onClick={() => setShowExample((prev) => !prev)}
            >
              {showExample ? `Skjul eksempel` : `Vis eksempel`}
            </a>
          </div>
          <Accordion toggled={showMergeFields}>
            <div>
              <h4 tw="my-0">Flettefelter</h4>
              <ul tw="mt-2 text-xl list-none p-0">
                {mergeFields.map((field) => renderMergeField(field))}
              </ul>
            </div>
          </Accordion>
        </Accordion>
      </div>
      {onSave && onCancel && (
        <Responsive
          phone={
            <EditorButtonGroup
              sticky
              onSave={onSaveHandler}
              onCancel={onCancelHandler}
            />
          }
          tabletLandscape={
            <EditorButtonGroup
              sticky={false}
              onSave={onSaveHandler}
              onCancel={onCancelHandler}
            />
          }
        />
      )}
    </StyledNotifications>
  )
}
